import { gql } from "@apollo/client";

export const GET_MENU = gql`
  query CmsCoreGetMenu {
    cmsCoreGetMenu {
    payload {
      cms_menu_id
      cms_menu_name
      cms_menu_rank
      cms_menu_icon
      cms_menu_endpoint
      cms_sub_menu {
        cms_sub_menu_id
        cms_sub_menu_name
        cms_sub_menu_rank
        cms_sub_menu_endpoint
      }
    }
  }
}
`;

export const GET_MENU_BY_ROLE = gql`
query CmsCoreGetMenuByRole {
  cmsCoreGetMenuByRole {
    success
    message
    code
    payload {
      cms_menu_id
      cms_menu_name
      cms_menu_rank
      cms_menu_icon
      cms_menu_endpoint
      cms_sub_menu {
        cms_sub_menu_id
        cms_sub_menu_name
        cms_sub_menu_rank
        cms_sub_menu_endpoint
      }
    }
  }
}
`;
