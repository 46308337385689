/*global process*/
/*eslint no-undef: "error"*/

import axios from "axios";
import * as React from "react";
import Cookies from "js-cookie";
import { LOGOUT } from "../gql/logout";
import LayoutIndex from "./indexLayout";
// import { menu } from "../config/menu-v2";
import { CMSAlert } from "@gtt/style-guide";
import { CMS_CHECK_TOKEN } from "../gql/checkToken";
import LogoutIcon from "@mui/icons-material/Logout";
import { Link, useLocation } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircularProgress from "@mui/material/CircularProgress";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { GET_MENU } from "../gql/menu";
import DynamicIcon from "./dynamicIcon";

import {
  Box,
  List,
  Drawer,
  Avatar,
  Collapse,
  ListItem,
  Backdrop,
  Typography,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";

import viewMore from "../assets/icon/view_more.svg";
import lessView from "../assets/icon/less_view.svg";
import sawasdeeMini from "../assets/icon/sawasdee_mini.svg";
import sawasdeeFull from "../assets/icon/sawasdee_full.svg";

const theme = createTheme({
  typography: {
    fontFamily: "'Noto Sans Thai','Arial',sans-serif",
  },
  palette: {
    background: { blue: "#2196F3", paper: "#2196F3" },
    text: { primary: "#ffffff" },
    icon: { primary: "#ffffff" },
  },
});

const checkChild = (item, onClick) => {
  const userRole = JSON.parse(localStorage.getItem("user_info"))?.user_type;
  // if (item.role?.includes(userRole)) {
  const Component = !item?.link ? MultiLevel : SingleLevel;
  return <Component item={item} onClick={onClick} />;
  // }
  return null;
};

const renderIcon = (icon) => {
  return icon ? (
    <DynamicIcon iconName={icon} key={icon} sx={{ marginRight: "32px" }} />
  ) : (
    <></>
  );
};

const SingleLevel = ({ item, onClick }) => {
  return (
    <Link
      to={item.link}
      onClick={onClick}
      style={{ textDecoration: "none", color: "#fff" }}
    >
      <ListItem
        sx={{
          "&:hover": {
            borderRadius: "4px",
            backgroundColor: "#64B5F6",
          },
        }}
      >
        {renderIcon(item.icon)}
        <ListItemText
          primary={
            <Typography
              children={item.title}
              fontSize={item?.icon ? 16 : 14}
              fontWeight={item?.icon ? 700 : 400}
            />
          }
        />
      </ListItem>
    </Link>
  );
};

const MultiLevel = ({ item, onClick }) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => setOpen((prev) => !prev);

  return (
    <React.Fragment>
      <ListItem
        onClick={handleClick}
        sx={{
          cursor: "default",
          "&:hover": {
            borderRadius: "4px",
            backgroundColor: "#64B5F6",
          },
        }}
      >
        {renderIcon(item.icon)}
        <ListItemText
          primary={
            <Typography children={item.title} fontSize={16} fontWeight={700} />
          }
        />
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding sx={{ pl: 7, pb: 2 }}>
          {item.children?.map((child, index) => (
            <React.Fragment key={index}>
              {checkChild(child, onClick)}
            </React.Fragment>
          ))}
        </List>
      </Collapse>
    </React.Fragment>
  );
};

const clearData = () => {
  Cookies.remove("access_token");
  localStorage.removeItem("user_info");
  localStorage.removeItem("langSet");
  window.location.replace("/login");
};

const modalForceLogout = async () => {
  CMSAlert({
    title: "AUTHORIZE FAILED",
    textInComfirmButton: "CONFIRM",
    confirmButtonColor: "#FF0800",
    onComfirmClick: () => clearData(),
  });
};

export default function Nav() {
  const location = useLocation();
  const [sideMenu, setSideMenu] = React.useState(false);
  const [userInfo, setUserInfo] = React.useState({
    cms_firstname: "",
    cms_lastname: "",
    cms_email: "",
    user_type: "",
  });
  const [menu, setMenu] = React.useState([]);

  const { data } = useQuery(GET_MENU);
  React.useEffect(() => {
    if (data) {
      // const formattedSubMenu = data.cmsCoreGetMenu.payload.map((menu) => ({}));
      const formattedMenu = data.cmsCoreGetMenu.payload.map((menu) => {
        const formattedSubMenu = menu.cms_sub_menu.map((subMenu) => {
          return {
            link: subMenu.cms_sub_menu_endpoint || "",
            title: subMenu.cms_sub_menu_name || "",
            icon: subMenu?.cms_sub_menu_icon || "",
            role: "ADMIN",
          };
        });
        return {
          link: menu.cms_menu_endpoint || "",
          title: menu.cms_menu_name || "",
          icon: menu?.cms_menu_icon || "",
          children: formattedSubMenu || [],
          role: "ADMIN",
        };
      });
      setMenu(formattedMenu);
    }
  }, [data]);

  const [logout, { loading }] = useMutation(LOGOUT, {
    onCompleted: () => clearData(),
  });

  const [checkToken] = useLazyQuery(CMS_CHECK_TOKEN, {
    onCompleted: (data) => {
      if (!data.cmsCheckToken.success) {
        modalForceLogout();
      }
    },
    onError: (e) => modalForceLogout(),
  });

  const toggleDrawer = (open) => () => {
    setSideMenu(open);
  };

  const handleLogout = () => () => {
    setSideMenu(false);
    CMSAlert({
      title: "Are you sure?",
      description: "You want to logout?",
      textInComfirmButton: "CONFIRM",
      textInCancelButton: "CANCEL",
      cancelButton: true,
      onComfirmClick: () => logout(),
    });
  };

  const list = () => (
    <Box role="presentation" onKeyDown={toggleDrawer(false)}>
      <List>
        {menu.map((row, index) => {
          return (
            <React.Fragment key={index}>
              {checkChild(row, toggleDrawer(false))}
            </React.Fragment>
          );
        })}
      </List>
    </Box>
  );

  React.useMemo(async () => {
    checkToken();
    try {
      const getLang = await axios({
        url: `${process.env.API_BACKBONE}/language?tms_platform_id=1`,
        headers: { "Content-Type": "application/json" },
        method: "get",
        timeout: 5000,
      });
      localStorage.setItem("langSet", JSON.stringify(getLang.data.payload));
    } catch (error) {
      const lang = [{ key: "en", name: "English (US)" }];
      localStorage.setItem("langSet", JSON.stringify(lang));
    }
  }, []);

  const decodeToken = (accessToken) => {
    if (accessToken) {
      try {
        const [, payload] = accessToken.split(".");
        const decodedToken = JSON.parse(atob(payload)); // You can use the decoded token information here if needed
        return decodedToken.user_type || "ADMIN";
      } catch (error) {
        console.error("Error decoding access token:", error);
      }
    }
  };

  React.useEffect(() => {
    const data = localStorage.getItem("user_info");
    const accessToken = Cookies.get("access_token");
    const decode = decodeToken(accessToken);
    const parseData = {
      ...JSON.parse(data),
      user_type: decode,
    };
    localStorage.setItem("user_info", JSON.stringify(parseData));
    setUserInfo(parseData);
  }, []);

  const shortName = `${userInfo.cms_firstname.charAt(
    0
  )}${userInfo.cms_lastname.charAt(0)}`;

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        open={loading}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress />
      </Backdrop>
      <Box
        sx={{
          bgcolor: "background.blue",
          position: "fixed",
          height: "100vh",
          width: 80,
          left: 0,
          top: 0,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ py: 3, display: "flex", justifyContent: "center" }}>
          <img src={sawasdeeMini} alt="" />
          <Box
            sx={{ position: "absolute", right: "-16px" }}
            onClick={toggleDrawer(true)}
          >
            <img src={viewMore} alt="" />
          </Box>
        </Box>
        <Box
          sx={{
            gap: 2,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
          onClick={toggleDrawer(true)}
        >
          {menu
            // .filter((row) => {
            //   const userInfo = JSON.parse(localStorage.getItem("user_info"));
            //   return row.role.includes(userInfo?.user_type);
            // })
            .map((row, index) => {
              return <DynamicIcon iconName={row.icon} key={index} />;
            })}
        </Box>
        <Box
          sx={{
            height: "80px",
            borderTop: " 1px solid #64B5F6",
            width: "80px",
            mt: "auto",
            position: "fixed",
            bottom: 0,
            backgroundColor: "#2196F3",
          }}
        >
          <Box
            sx={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Avatar sx={{ bgcolor: "#F5F5F5", color: "#37444F" }}>
              {shortName}
            </Avatar>
          </Box>
        </Box>
      </Box>
      <Drawer
        open={sideMenu}
        sx={{
          position: "relative",
          ".MuiPaper-root": { overflow: "initial" },
        }}
        onClose={toggleDrawer(false)}
      >
        <Box sx={{ width: "310px", height: "100vh" }}>
          <Box sx={{ height: "7%" }}>
            <Box sx={{ p: 3, position: "relative" }}>
              <img src={sawasdeeFull} alt="" />
              <Box
                sx={{
                  top: "50%",
                  right: "-16px",
                  position: "absolute",
                  transform: "translate(0%, -50%)",
                }}
                onClick={toggleDrawer(false)}
              >
                <img src={lessView} alt="" />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              height: "85%",
              overflow: "scroll",
              "::-webkit-scrollbar": {
                display: "none",
              },
              mx: "8px",
            }}
          >
            {list()}
          </Box>
          <Box
            sx={{
              height: "80px",
              borderTop: " 1px solid #64B5F6",
              width: "310px",
              mt: "auto",
              position: "fixed",
              bottom: 0,
              backgroundColor: "#2196F3",
            }}
          >
            <Box
              sx={{
                px: 2,
                height: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ bgcolor: "#F5F5F5", color: "#37444F", mr: 1 }}>
                {shortName}
              </Avatar>
              <Box sx={{ overflow: "hidden" }}>
                <Tooltip title={userInfo.cms_firstname}>
                  <Typography fontSize={14} fontWeight={700} noWrap>
                    {userInfo.cms_firstname}
                  </Typography>
                </Tooltip>
                <Tooltip title={userInfo.cms_email}>
                  <Typography fontSize={14} noWrap>
                    {userInfo.cms_email}
                  </Typography>
                </Tooltip>
              </Box>
              <LogoutIcon onClick={handleLogout()} sx={{ ml: "auto" }} />
            </Box>
          </Box>
        </Box>
      </Drawer>
      {location.pathname === "/" && <LayoutIndex userInfo={userInfo} />}
    </ThemeProvider>
  );
}
